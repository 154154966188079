import React from "react"
import { EmployeeTestimonials, Hero, JobOpenings, Main, Metadata, OurCulture, ThinkBig } from "components"
import { content } from "content/careers"
import { graphql, useStaticQuery } from "gatsby"

const Careers = () => {
	const careersHero = useStaticQuery(graphql`
		query careersHero {
			file(relativePath: { eq: "hero_careers.png" }) {
				relativePath
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	return (
		<>
			<Main>
				<Metadata
					pageTitle="Join the Venfam"
					pageDescription="How do I apply for a position at Venrup? We'd love to have more talent join our team! For all career opportunities, check out our career page here!"
				/>
				<Hero
					img={careersHero}
					title={content.hero.title}
					description={content.hero.description}
					buttonText={content.hero.button.text}
					buttonLink={content.hero.button.path}
				/>
				<OurCulture />
				<JobOpenings />
				{/* <Testimonials /> */}
				<EmployeeTestimonials />
				<ThinkBig />
			</Main>
		</>
	)
}

export default Careers
